<template>
  <div class='service-contain'>
    <div class="col-xs-12 col-md-12 col-lg-7">
     <div class="service-body"
      :class="ar ? 'text-right' : 'text-left'"  
      >
        <div
           class="service-header"
        >
          Find your Home services very easy
        </div>
        <div 
          class="subheader-home"
        >
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s 
        </div>
        <div 
          class="service-footer"
        >
          <button class="btn-pri-lg">
            Explore More
          </button>
        </div>
     </div>
    </div>
    <div 
      class="image col-md-5 col-lg-5"
      v-if="width == 'lg'"
    >
      <img src='../../assets/images/blog/4.jpg'/>
    </div>

  </div>

</template>

<script>
export default {
  computed: {
    width() {
      return this.$store.getters.width;
    },
    ar() {
      return this.$i18n.locale == 'ar';
    }
  }
}
</script>