<template>
  <div class="row res-card " :class="[(ar ? 'flex-row-reverse' : 'flex-row'), (width == 'sm' ? 'px-1' : '')]">
    <div class="col-md-12 col-lg-5 col-xl-3 res-header" >
      <div class="res-image" @click='goProfile(item.buisness.id)'>
        <img :src="item.buisness.media.length ? item.buisness.media[0].url : 'https://images.squarespace-cdn.com/content/v1/5387997be4b0bd6d1373cbff/1610119502963-EYNJP5CB5847764TVFRR/ke17ZwdGBToddI8pDm48kDAv91l_EVpAtt3T82Wm1m9Zw-zPPgdn4jUwVcJE1ZvWEtT5uBSRWt4vQZAgTJucoTqqXjS3CfNDSuuf31e0tVGRdKQdAvYPFeoNHcl0B9NbYhRtIalXltUl4wqyICqU51hQUjdct07RUVzezzWpvVo/GraySquare.jpg'" />
        <!-- <div class="item-address">
          <button class="btn-pri text-ellipsis"> {{item.buisness.area}} </button>
        </div> -->
      </div>
    </div>
    <div class=" col-md-12 col-lg-7 col-xl-9 res-details">
      <div class="row px-1">
        <div class="col-12 mt-sm-3 mt-md-2 mt-lg-2"  >
          <div 
            class="res-name pointer"
            :class="ar ? 'f-right' : 'f-left'"
             @click='goProfile(item.buisness.id)'
          >
              {{item.name}}
          </div>
          <div 
            v-show='item.buisness.is_open_now'
            class="badge-pri mt-1"
            :class="ar ? 'f-right' : 'f-left'"    
          >
            {{$t('open')}}
          </div>
          <div 
            class="res-btns"
            :class="ar ? 'f-left' : 'f-right'"
          >
            <button class="btn-def" @click="goMap(item.buisness.location.address_link)">
              <i class="fa fa-map"></i>
              {{$t('directions')}}
            </button>
            <button class="btn-icon-pri" @click="goPhone(item.buisness.location.phone)">
              <i class="fa fa-phone"></i>
            </button>
          </div>
        </div>
        <div class="col-12">
          <div 
            :class="ar ? 'text-right' : 'text-left'"
            class="res-categories text-underline">{{htmlDecode(item.buisness.category.name)}}</div>
        </div>
        <div class="col-12">
          <div :class="ar ? 'text-right' : 'text-left'">
            <Stars :stars='item.buisness.rate' class="col-xs-6 pa-0"/>
            <div class="col-xs-9">
              <span class="del-badge" v-show='item.buisness.shipping_free || item.buisness.delivery_price'>
                <i class="fa fa-check-circle"></i>
                {{$t('delivery')}}
              </span>
              <span class="del-badge" v-show='false'>
                <i class="fa fa-check-circle"></i>
                {{$t('takeAway')}}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="divider"></div>
      <div class="">
        <div class="res-desc col-12 d-flex "
          :class="ar ? 'justify-content-end' : 'justify-content-start'"
          v-html='item.buisness.description'
        >
          <!-- {{item.buisness.description}} -->
        </div>
        <div class="res-footer col-12 "
          :class="ar ? 'text-right' : 'text-left'"
        >
          <button class="btn-pri my-1" v-if='getBook() != false' :class="width == 'sm' ? 'w-100' : ''" @click='goProfile(item.buisness.id, 3)'>
            <!-- <i class="fa fa-map"></i> -->
            {{$t('startOrder')}}
          </button>
          <button class="btn-pri my-1" v-else :class="width == 'sm' ? 'w-100' : ''" @click='goReserve(item.buisness.id)'>
            <!-- <i class="fa fa-map"></i> -->
            {{$t('Checkin')}}
          </button>
          <button class="btn-pri  my-1" :class="width == 'sm' ? 'w-100' : ''" v-if='item.buisness.allow_booking || item.buisness.allow_checkin' @click='goReserve(item.buisness.id)'>
            <!-- <i class="fa fa-map"></i> -->
            {{$t('Reserve')}}
          </button>
          <!-- <button class="btn-icon-sec">
            <i class="fa fa-heart-o"></i>
          </button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Stars from '@/components/misc/Stars'
import { helpers } from '../../Helpers';
export default {
  components: {
    Stars
  },
  mixins: [helpers],
  props: ['item'],
  computed: {
    ar() {
      return this.$i18n.locale == 'ar';
    },
    width() {
      return this.$store.getters.width;
    }
  },
  methods: {
    goReserve(id) {
      this.$router.push({
        path: `/${this.$i18n.locale}/${id}`,
      })
    },
    goProfile(id, tab = 1) {
      this.$router.push({
        name: 'BusinessProfile',
        params: {
          locale: this.$i18n.locale,
          id,
          tab,
        }
      })
    },
    goMap(link) {
      window.location.href = link;
    },
    goPhone(link) {
      window.location.href = `tel:${link}`;
    }
  }
}
</script>