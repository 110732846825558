<template>
<div class='mx-9'>
  <div style="height: 100px"></div>
   <div class="container-fluid ">
    <!-- <HomeSearch /> -->
    <div class="col-md-4 col-xs-12">
      <Event />
    </div>
    <div class="col-xs-12">
      <DownloadApp />
    </div>
    <div>
      <button @click='filter' class="btn-pri">
        Herer
      </button>
    </div>
  </div>

    
  </div>
</template>

<script>
import AppHeader from '@/components/AppHeader';
import AppFooter from '@/components/AppFooter';
import AroundMe from '@/components/AroundMe';
import Stars from '@/components/misc/Stars'
import Pagination from '../components/misc/Pagination.vue';
import DownloadApp from '../components/misc/DownloadApp.vue';
import WriteReview from '@/components/forms/WriteReview.vue';
import HomeSearch from '@/components/forms/HomeSearch.vue';
import CategoryCard from '@/components/Listings/CategoryCard.vue';
import Highlited from '@/components/Listings/Highlited.vue';
import Event from '@/components/Listings/Event';
import resultCard from '@/components/Listings/resultCard';
import PopProduct from '@/components/Listings/PopProduct';
import Review from '@/components/Listings/Review';
import ServiceCard from '@/components/Listings/ServiceCard';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
export default {
  name: 'About',
  components: {
    AppHeader,
    AppFooter,
    AroundMe,
    Review,
    Stars,
    Pagination,
    WriteReview,
    CategoryCard,
    Highlited,
    HomeSearch,
    Event,
    ServiceCard,
    resultCard,
    PopProduct,
    DownloadApp,
    Splide, SplideSlide,
  },
  data() {
    return {
      category: {
        id: 10,
        name: 'super Market',
        items: 200
      }
    }
  },
  mounted(){
    console.log(this.$store.state.width);
    this.$loadScript('../../js/bootstrap.min.js');
    $('.carousel').carousel({
      interval: false,
    });
  },
  computed: {
    width() {
       return this.$store.getters.width;
    } 
  },
  methods: {
    carNext() {
      $('.carousel').carousel('next');
    },
    carPrev() {
      $('.carousel').carousel('prev');
    },
    filter() {
      $('.modal').modal('show')
    }
  }
}

</script>

<style lang="scss" scoped>

</style>